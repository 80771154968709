import { graphql } from '../generated-gql';

export const mutationUserSubscribeNewsletter = graphql(`
  mutation UserSubscribeNewsletter($email: String!) {
    userSubscribeNewsletter(email: $email) {
      id
      isSubscribed
    }
  }
`);
