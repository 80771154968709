import { graphql } from '../generated-gql';

export const mutationAdminCategoryUpdate = graphql(`
  mutation CategoryUpdate($input: CategoryUpdateInput!) {
    categoryUpdate(input: $input) {
      id
      categoryName
    }
  }
`);
