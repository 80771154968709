import type { ComponentPropsWithoutRef, PropsWithChildren } from 'react';
import NextLink from 'next/link';
import { cx } from 'cva';
import { Button, ButtonAsLink, ProgressWeb } from '@inkd/ui';
import { Navbar } from '@/components/Navbar';

interface CheckoutLinkProps extends ComponentPropsWithoutRef<'a'> {
  'data-as': 'link';
  href: string;
  loading?: boolean;
}

interface CheckoutButtonProps extends ComponentPropsWithoutRef<'button'> {
  'data-as': 'button';
  loading?: boolean;
}

export interface CheckoutProps extends PropsWithChildren, CheckoutButtonsProps {
  currentStep: Parameters<typeof ProgressWeb>[0]['currentStep'];
  /** If defined, a `<form>` tag will wrap the `{children}` of this component. If undefined (default), it won't. */
  formProps?: ComponentPropsWithoutRef<'form'>;
}

/**
 * The layout used throughout the whole mobile checkout flow process.
 *
 * Expects the root element passed to `children` to be a `<main>` tag.
 *
 * If the `formProps` prop is defined, it will wrap the `{children}` of this component with a `<form>` tag and spread all the props in the `formProps` object onto it
 */
export function CheckoutLayout({
  currentStep,
  children,
  cancelButton,
  confirmButton,
  formProps,
}: CheckoutProps) {
  return (
    <div className='mx-auto max-w-screen-md bg-white'>
      <Navbar flow='checkout' />

      <ProgressWeb currentStep={currentStep} />

      {formProps ? (
        <form
          {...formProps}
          className={cx('flex flex-col', formProps.className)}
        >
          {children}
          <CheckoutButtons
            cancelButton={cancelButton}
            confirmButton={confirmButton}
          />
        </form>
      ) : (
        <>
          {children}
          <CheckoutButtons
            cancelButton={cancelButton}
            confirmButton={confirmButton}
          />
        </>
      )}
    </div>
  );
}

export interface CheckoutButtonsProps {
  cancelButton: CheckoutButtonProps | CheckoutLinkProps;
  confirmButton: CheckoutButtonProps | CheckoutLinkProps;
}

function CheckoutButtons({
  cancelButton,
  confirmButton,
}: CheckoutButtonsProps) {
  return (
    <>
      <div className='p-i-3 p-b-6 flex items-center justify-between gap-x-3'>
        {cancelButton['data-as'] === 'link' ? (
          <ButtonAsLink
            icon='ArrowLeft'
            variant='secondary'
            className='w-max'
            NextLink={NextLink}
            {...cancelButton}
          />
        ) : (
          <Button
            icon='ArrowLeft'
            variant='secondary'
            className='w-max'
            {...cancelButton}
          />
        )}
        {confirmButton['data-as'] === 'link' ? (
          <ButtonAsLink
            icon='ArrowRight'
            NextLink={NextLink}
            className='w-max'
            {...confirmButton}
          />
        ) : (
          <Button icon='ArrowRight' className='w-max' {...confirmButton} />
        )}
      </div>
    </>
  );
}
