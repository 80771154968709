import { graphql } from '../generated-gql';

export const queryOrders = graphql(`
  query Orders($input: OrdersInput) {
    orders(input: $input) {
      id
      status
      pickupKioskId
      orderPlacedFrom
      orderStartedTimestamp
      orderCompletedTimestamp
      totalPrice
      userEmail
      user {
        email
      }
    }
  }
`);
