import { graphql } from '../generated-gql';

export const mutationAdminCardImagesUploadURL = graphql(`
  mutation CardImagesUploadURL($cardId: ID!) {
    cardImagesUploadURL(cardId: $cardId) {
      coverImageUploadURL
      interiorImageUploadURL
    }
  }
`);
