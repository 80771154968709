import { graphql } from '../generated-gql';

export const mutationUpdateOrder = graphql(`
  mutation OrderUpdate($input: OrderUpdateInput!) {
    orderUpdate(input: $input) {
      id
      pickupCode
      totalPrice
    }
  }
`);
