import { graphql } from '../generated-gql';

export const mutationAdminLogin = graphql(`
  mutation AdminLogin($input: AdminLoginInput!) {
    adminLogin(input: $input) {
      user {
        id
        firstName
        lastName
        email
        role
      }
      token
    }
  }
`);
