import { graphql } from '../generated-gql';

export const mutationAdminCategoryDelete = graphql(`
  mutation CategoryDelete($id: ID!) {
    categoryDelete(id: $id) {
      id
      categoryName
    }
  }
`);
