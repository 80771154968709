import { graphql } from '../generated-gql';

export const mutationCreateOrder = graphql(`
  mutation OrderCreate($input: OrderCreateInput!) {
    orderCreate(input: $input) {
      id
      totalPrice
    }
  }
`);
