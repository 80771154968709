import { graphql } from '../generated-gql';

export const mutationAdminCardUpdate = graphql(`
  mutation CardUpdate($input: CardUpdateInput!) {
    cardUpdate(input: $input) {
      id
      cardName
    }
  }
`);
