import { graphql } from '../generated-gql/gql';

export const queryGiftCards = graphql(`
  query GiftCards {
    giftCards {
      denominationType
      id
      name
      denominations
      faceplatePath
      favoriteRank
      provider
    }
  }
`);
