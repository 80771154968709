import { graphql } from '../generated-gql';

export const mutationAdminCardDelete = graphql(`
  mutation CardDelete($cardDeleteId: ID!) {
    cardDelete(id: $cardDeleteId) {
      id
      cardName
    }
  }
`);
