import { graphql } from '../generated-gql';

export const queryOrderForPickup = graphql(`
  query OrderForPickup($orderId: ID!) {
    order(id: $orderId) {
      status
      orderDetails {
        id
        orderId
        card {
          id
        }
        giftCardTransaction {
          denomination
          vendor {
            faceplatePath
            id
            name
          }
        }
        signature
        signatureFont
        customMessage
        quantity
        price
      }
    }
  }
`);
