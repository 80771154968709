import { graphql } from '../generated-gql';
import { QueryShippingOrdersQuery } from '../generated-gql/graphql';

export type ShippingOrders = NonNullable<
  NonNullable<QueryShippingOrdersQuery['orders']>[number]
>;

export const queryShippingOrders = graphql(`
  query queryShippingOrders($input: OrdersInput) {
    orders(input: $input) {
      city
      country
      line1
      line2
      name
      postalCode
      state
      id
      status
      pickupKioskId
      pickupCode
      orderPlacedFrom
      orderStartedTimestamp
      orderCompletedTimestamp
      totalPrice
      userEmail
      user {
        email
      }
    }
  }
`);
