import { graphql } from '../generated-gql';

export const mutationAdminCreate = graphql(`
  mutation AdminCreate($input: AdminCreateInput!) {
    adminCreate(input: $input) {
      id
      firstName
      lastName
      email
      role
    }
  }
`);
