import { graphql } from '../generated-gql/gql';
import { CategoryQuery } from '../generated-gql/graphql';

export const queryCategory = graphql(`
  query Category($categoryId: ID!) {
    category(categoryId: $categoryId) {
      id
      categoryName
      categoryType
      parent {
        id
        categoryName
      }
      featured
      seasonal
    }
  }
`);

export type NonNullableCategory = NonNullable<CategoryQuery['category']>;
