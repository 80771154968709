import { graphql } from '../generated-gql';

export const mutationAdminCategoryCreate = graphql(`
  mutation CategoryCreate($input: CategoryCreateInput!) {
    categoryCreate(input: $input) {
      id
      categoryName
    }
  }
`);
