import { graphql } from '../generated-gql';

export const mutationAdminCardCreate = graphql(`
  mutation CardCreate($input: CardCreateInput!) {
    cardCreate(input: $input) {
      id
      cardName
    }
  }
`);
