import { graphql } from '../generated-gql';

export const queryPrintQualityAssets = graphql(`
  query CardPrintQualityImage($cardPrintQualityImageId: ID!) {
    cardPrintQualityImage(id: $cardPrintQualityImageId) {
      main
      interior
    }
  }
`);
