import { graphql } from '../generated-gql';
import type {
  AdminCardsByCategoriesQuery,
  AdminCardsQuery,
} from '../generated-gql/graphql';

export const queryAdminCards = graphql(`
  query AdminCards($input: CardsInput) {
    cards(input: $input) {
      id
      isActive
      cardName
      sku
      rating
      ordering
      categories {
        id
        categoryName
      }
      designer {
        id
        name
      }
    }
  }
`);

/** A non-nullable version of the Cards from the AdminCards query type, once we've filtered out all the `null` and `undefined` cards */
export type AdminCard = NonNullable<
  NonNullable<AdminCardsQuery['cards']>[number]
>;

export const queryAdminCardsByCategory = graphql(`
  query AdminCardsByCategories($input: CardsByCategoriesInput!) {
    cardsByCategories(input: $input) {
      id
      isActive
      cardName
      sku
      rating
      ordering
      categories {
        id
        categoryName
      }
      designer {
        id
        name
      }
    }
  }
`);

/** A non-nullable version of the Cards from the AdminCardsByCategory query type, once we've filtered out all the `null` and `undefined` cards */
export type AdminCardByCategories = NonNullable<
  NonNullable<AdminCardsByCategoriesQuery['cardsByCategories']>[number]
>;
