import { graphql } from '../generated-gql/gql';
import type {
  AdminCategoriesQuery,
  CategoriesQuery,
} from '../generated-gql/graphql';

/**
 * Fetches all categories and their details for the homepage
 *
 * @see {@link `packages/graphql/gql/typeDefs.ts`} for documentation on the local
 * Apollo client usage for the `featured` property which we inject in our cache
 */
export const queryAllCategories = graphql(`
  query Categories($input: CategoriesInput!) {
    categories(input: $input) {
      id
      categoryName
      featured
      seasonal
      parent {
        id
        categoryName
      }
      cards {
        id
        cardName
        isActive
        sku
        images {
          id
          mainImageDescription
          previewMainPhotoUrl
          previewInteriorPhotoUrl
        }
      }
    }
  }
`);

export const queryAllCategoriesWithRatingFilter = graphql(`
  query CategoriesWithRatingFilter(
    $input: CategoriesInput!
    $maxRating: Int
    $cardsAfterDarkEnabled: Boolean
  ) {
    categories(input: $input) {
      id
      categoryName
      featured
      seasonal
      parent {
        id
        categoryName
      }
      cards(
        maxRating: $maxRating
        cardsAfterDarkEnabled: $cardsAfterDarkEnabled
      ) {
        id
        cardName
        isActive
        sku
        images {
          id
          mainImageDescription
          previewMainPhotoUrl
          previewInteriorPhotoUrl
        }
      }
    }
  }
`);

type FilteredCategoryMaybeWithCards = NonNullable<
  NonNullable<CategoriesQuery['categories']>[number]
>;
type FilteredCategoryCards = NonNullable<
  FilteredCategoryMaybeWithCards['cards']
>;
type FilteredCategoryCard = NonNullable<FilteredCategoryCards[number]>;

export interface FilteredCategory
  extends Omit<FilteredCategoryMaybeWithCards, 'cards'> {
  cards: FilteredCategoryCard[];
}

export const queryAdminAllCategories = graphql(`
  query AdminCategories($input: CategoriesInput!) {
    categories(input: $input) {
      id
      categoryName
      categoryType
      parent {
        id
        categoryName
      }
      featured
      seasonal
      cards(maxRating: 4) {
        id
      }
    }
  }
`);

type AdminCategoryWithNullableCards = NonNullable<
  AdminCategoriesQuery['categories'][number]
>;
type AdminCategoryCardNonNullable = NonNullable<
  NonNullable<AdminCategoryWithNullableCards['cards']>[number]
>;

/** A non-nullable version of the Category from the AdminCategoriesQuery type, once we've filtered out all the `null` and `undefined` categories */
export type AdminCategory = Omit<AdminCategoryWithNullableCards, 'cards'> & {
  cards: AdminCategoryCardNonNullable[];
};

export const queryAddonCategories = graphql(`
  query AddonCategories($input: CategoriesInput!) {
    categories(input: $input) {
      id
      categoryName
      parent {
        id
      }
    }
  }
`);
