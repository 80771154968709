import { type PropsWithChildren } from 'react';
import Head from 'next/head';
import { Navbar } from '@/components/Navbar';

export function CatalogLayout({
  pageTitle,
  errorText = 'No cards found.',
  children,
}: PropsWithChildren<{
  errorText?: string;
  pageTitle?: string;
}>) {
  return (
    <>
      <Head>
        <title>
          {pageTitle ? `Ink'd Greetings - ${pageTitle}` : `Ink'd Greetings`}
        </title>
      </Head>

      <Navbar flow='browsing' />

      <main className='mx-auto max-w-screen-md bg-white'>
        {children ?? (
          <p className='grid place-items-center'>
            <span className='text-heading2 text-tonal-60'>{errorText}</span>
          </p>
        )}
      </main>
    </>
  );
}
