import { graphql } from '../generated-gql';

export const queryCardsByCategory = graphql(`
  query CardsByCategories($input: CardsByCategoriesInput!) {
    cardsByCategories(input: $input) {
      id
      isActive
      cardName
      categories {
        id
        categoryName
      }
      images {
        id
        mainImageDescription
        previewMainPhotoUrl
        previewInteriorPhotoUrl
      }
    }
  }
`);
